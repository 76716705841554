import React from 'react';

export const clearError = (e: React.FocusEvent, cb: () => void) => {
    e.target.classList.remove('error')
    if (cb) cb()
}

export const translateStatus = (state: string) => {
    switch (state) {
        case 'active':
            return 'Активен'
        case 'waiting':
            return 'Ожидает'
        case 'expired':
            return 'Истёк'
    }
}

export const removeSeconds = (time: string) => {
    const timeSplit = time.split(':')
    return `${timeSplit[0]}:${timeSplit[1]}`
}

export const dateToPeopleView = (date: string, weekdayType: 'long' | 'short' | undefined = undefined) => {
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    }

    if (weekdayType) {
        options.weekday = weekdayType
    }

    return (new Date(date)).toLocaleDateString('ru-RU', options)
}