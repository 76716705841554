import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {PackType, ScheduleResponseType} from '../types';


type ScheduleParamsType = {
    id: number;
    fromDate: string;
    toDate: string;
    token: string;
}

type ActivePacksParamsType = {
    id: number;
    trainingId: number;
    token: string;
}

type SignUpTrainingType = {
    id: number;
    trainingId: number;
    token: string;
    trainingDate: string;
}

type SignUpTrainingResponseType = {
    message: string;
    result: number;
}

type CancelTrainingResponseType = {
    result: boolean;
    message: string;
}

type CancelTrainingParamsType = {
    recordId: number;
    token: string;
}

export const scheduleApi = createApi({
    reducerPath: 'scheduleApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_HOST,
        mode: 'cors',
    }),
    endpoints: (build) => ({
        getSchedule: build.query<ScheduleResponseType, ScheduleParamsType>({
            query: ({id, fromDate, toDate, token}) =>  ({
                url: `/gym/${id}/tt/${fromDate}:${toDate}`,
                params: {
                    token: token,
                }
            }),
        }),
        getActivePacks: build.query<Array<PackType>, ActivePacksParamsType>({
           query: ({id, trainingId, token}) => ({
               url: `/gym/${id}/packs/getactive/${trainingId}`,
               params: {
                   token: token,
               }
           })
        }),
        signUpTraining: build.query<SignUpTrainingResponseType, SignUpTrainingType>({
            query: ({trainingId, id, trainingDate, token}) => ({
                url: '/scheduler/add',
                params: {
                    id: trainingId,
                    gym_id: id,
                    date: trainingDate,
                    cnt: 1,
                    childs: 0,
                    token: token,
                    confirmed: 0,
                }
            })
        }),
        cancelTraining: build.query<CancelTrainingResponseType, CancelTrainingParamsType>({
            query: ({recordId, token}) => ({
                url: '/scheduler/cancel',
                params: {
                    record_id: recordId,
                    token: token,
                }
            })
        })
    })
});

export const {
    useGetScheduleQuery,
    useLazyGetActivePacksQuery,
    useLazySignUpTrainingQuery,
    useLazyCancelTrainingQuery,
} = scheduleApi
