import React, {FC} from 'react';

const CircleText: FC = () => {
    return (
        <div style={{position: 'relative', height: '11.5847em'}}>
            <span
                style={{
                    position: 'absolute',
                    bottom: 'auto',
                    left: '50%',
                    transform: 'translateX(-0.515625em) rotate(-173.117deg)',
                    transformOrigin: 'center 5.79237em'
                }}
            >
                М
            </span>
            <span
                style={{
                    position: 'absolute',
                    bottom: 'auto',
                    left: '50%',
                    transform: 'translateX(-0.503418em) rotate(-159.515deg)',
                    transformOrigin: 'center 5.79237em'
                }}>
                Ы
            </span>
            <span
                style={{
                    position: 'absolute',
                    bottom: 'auto',
                    left: '50',
                    transform: 'translateX(-0.499512em) rotate(-146.127deg)',
                    transformOrigin: 'center 5.79237em'
                }}>
                &nbsp;
            </span>
            <span
                style={{
                    position: 'absolute',
                    bottom: 'auto',
                    left: '50%',
                    transform: 'translateX(-0.446289em) rotate(-133.503deg)',
                    transformOrigin: 'center 5.79237em'
                }}>
                Н
            </span>
            <span
                style={{
                    position: 'absolute',
                    bottom: 'auto',
                    left: '50%',
                    transform: 'translateX(-0.416504em) rotate(-121.986deg)',
                    transformOrigin: 'center 5.79237em'
                }}>
                А
            </span>
            <span
                style={{
                    position: 'absolute',
                    bottom: 'auto',
                    left: '50%',
                    transform: 'translateX(-0.378906em) rotate(-111.368deg)',
                    transformOrigin: 'center 5.79237em'
                }}>
                У
            </span>
            <span
                style={{
                    position: 'absolute',
                    bottom: 'auto',
                    left: '50%',
                    transform: 'translateX(-0.405273em) rotate(-100.901deg)',
                    transformOrigin: 'center 5.79237em'
                }}>
                Ч
            </span>
            <span
                style={{
                    position: 'absolute',
                    bottom: 'auto',
                    left: '50%',
                    transform: 'translateX(-0.446289em) rotate(-89.534deg)',
                    transformOrigin: 'center 5.79237em'
                }}>
                И
            </span>
            <span
                style={{
                    position: 'absolute',
                    bottom: 'auto',
                    left: '50%',
                    transform: 'translateX(-0.515625em) rotate(-76.6941deg)',
                    transformOrigin: 'center 5.79237em'
                }}>
                М
            </span>
            <span
                style={{
                    position: 'absolute',
                    bottom: 'auto',
                    left: '50%',
                    transform: 'translateX(-0.499512em) rotate(-63.1437deg)',
                    transformOrigin: 'center 5.79237em'
                }}>
                &nbsp;
            </span>
            <span
                style={{
                    position: 'absolute',
                    bottom: 'auto',
                    left: '50%',
                    transform: 'translateX(-0.400391em) rotate(-51.1315deg)',
                    transformOrigin: 'center 5.79237em'
                }}>
                В
            </span>
            <span
                style={{
                    position: 'absolute',
                    bottom: 'auto',
                    left: '50%',
                    transform: 'translateX(-0.407715em) rotate(-40.3447deg)',
                    transformOrigin: 'center 5.79237em'
                }}>
                А
            </span>
            <span
                style={{
                    position: 'absolute',
                    bottom: 'auto',
                    left: '50%',
                    transform: 'translateX(-0.391602em) rotate(-29.6752deg)',
                    transformOrigin: 'center 5.79237em'
                }}>
                С
            </span>
            <span
                style={{
                    position: 'absolute',
                    bottom: 'auto',
                    left: '50%',
                    transform: 'translateX(-0.499512em) rotate(-17.7804deg)',
                    transformOrigin: 'center 5.79237em'
                }}>
                &nbsp;
            </span>
            <span
                style={{
                    position: 'absolute',
                    bottom: 'auto',
                    left: '50%',
                    transform: 'translateX(-0.39502em) rotate(-5.83988deg)',
                    transformOrigin: 'center 5.79237em'
                }}>
                К
            </span>
            <span
                style={{
                    position: 'absolute',
                    bottom: 'auto',
                    left: '50%',
                    transform: 'translateX(-0.366699em) rotate(4.32777deg)',
                    transformOrigin: 'center 5.79237em'
                }}>
                Р
            </span>
            <span
                style={{
                    position: 'absolute',
                    bottom: 'auto',
                    left: '50%',
                    transform: 'translateX(-0.407715em) rotate(14.6649deg)',
                    transformOrigin: 'center 5.79237em'
                }}>
                А
            </span>
            <span
                style={{
                    position: 'absolute',
                    bottom: 'auto',
                    left: '50%',
                    transform: 'translateX(-0.391602em) rotate(25.3344deg)',
                    transformOrigin: 'center 5.79237em'
                }}>
                С
            </span>
            <span
                style={{
                    position: 'absolute',
                    bottom: 'auto',
                    left: '50%',
                    transform: 'translateX(-0.446289em) rotate(36.5188deg)',
                    transformOrigin: 'center 5.79237em'
                }}>
                И
            </span>
            <span
                style={{
                    position: 'absolute',
                    bottom: 'auto',
                    left: '50%',
                    transform: 'translateX(-0.408691em) rotate(47.9313deg)',
                    transformOrigin: 'center 5.79237em'
                }}>
                В
            </span>
            <span
                style={{
                    position: 'absolute',
                    bottom: 'auto',
                    left: '50%',
                    transform: 'translateX(-0.473633em) rotate(59.7089deg)',
                    transformOrigin: 'center 5.79237em'
                }}>
                О
            </span>
            <span
                style={{
                    position: 'absolute',
                    bottom: 'auto',
                    left: '50%',
                    transform: 'translateX(-0.515625em) rotate(72.9138deg)',
                    transformOrigin: 'center 5.79237em'
                }}>
                М
            </span>
            <span
                style={{
                    position: 'absolute',
                    bottom: 'auto',
                    left: '50%',
                    transform: 'translateX(-0.378906em) rotate(84.8543deg)',
                    transformOrigin: 'center 5.79237em'
                }}>
                У
            </span>
            <span
                style={{
                    position: 'absolute',
                    bottom: 'auto',
                    left: '50%',
                    transform: 'translateX(-0.499512em) rotate(96.5796deg)',
                    transformOrigin: 'center 5.79237em'
                }}>
                &nbsp;
            </span>
            <span
                style={{
                    position: 'absolute',
                    bottom: 'auto',
                    left: '50%',
                    transform: 'translateX(-0.395508em) rotate(108.527deg)',
                    transformOrigin: 'center 5.79237em'
                }}>
                Б
            </span>
            <span
                style={{
                    position: 'absolute',
                    bottom: 'auto',
                    left: '50%',
                    transform: 'translateX(-0.473633em) rotate(120.128deg)',
                    transformOrigin: 'center 5.79237em'
                }}>
                О
            </span>
            <span
                style={{
                    position: 'absolute',
                    bottom: 'auto',
                    left: '50%',
                    transform: 'translateX(-0.379883em) rotate(131.521deg)',
                    transformOrigin: 'center 5.79237em'
                }}>
                К
            </span>
            <span
                style={{
                    position: 'absolute',
                    bottom: 'auto',
                    left: '50%',
                    transform: 'translateX(-0.391602em) rotate(141.819deg)',
                    transformOrigin: 'center 5.79237em'
                }}>
                С
            </span>
            <span
                style={{
                    position: 'absolute',
                    bottom: 'auto',
                    left: '50%',
                    transform: 'translateX(-0.378906em) rotate(152.104deg)',
                    transformOrigin: 'center 5.79237em'
                }}>
                У
            </span>
            <span
                style={{
                    position: 'absolute',
                    bottom: 'auto',
                    left: '50%',
                    transform: 'translateX(-0.499512em) rotate(163.83deg)',
                    transformOrigin: 'center 5.79237em'
                }}>
                &nbsp;
            </span>
            <span
                style={{
                    display: 'none',
                    position: 'absolute',
                    bottom: 'auto',
                    left: '50%',
                    transform: 'translateX(-0.355957em) rotate(175.249deg)',
                    transformOrigin: 'center 5.79237em'
                }}>
                Z
            </span>
        </div>
    );
};

export default CircleText;
