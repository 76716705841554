import React, {FC} from 'react';
import Header from '../components/Header';
import {Outlet} from 'react-router-dom';
import Footer from '../components/Footer';
import Container from '../styles/components/Container';

const MainLayout: FC = () => {
    return (
        <div>
            <Header/>
            <Container>
                <Outlet/>
            </Container>
            <Footer/>
        </div>
    );
};

export default MainLayout;
