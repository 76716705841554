export const isValidEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
}

export const isValidCode = (code: string) => {
    return /^\d{4}$/.test(code);
}

export const isValidPhone = (phone: string) => {
    return phone.length === 11
}