import React, {FC} from 'react';
import {Breadcrumbs} from '../components';
import {useLocation} from 'react-router-dom';
import {StyledReviews} from '../styles/StyledReviews';
import useDocumentTitle from '../utils/useDocumentTitle';

const Reviews: FC = () => {
    const location = useLocation();

    useDocumentTitle('Отзывы | Искусство бокса', true);

    return (
        <>
            <Breadcrumbs
                location={location}
                title='Отзывы'
            />
            <div>
                <StyledReviews.Title>Новости мира бокса</StyledReviews.Title>
                <StyledReviews.Content>
                    <StyledReviews.Image src={require('../assets/reviews/sanah.jpg')}/>
                    <StyledReviews.Content>
                        <StyledReviews.ContentTitle>Онлайн семинар</StyledReviews.ContentTitle>
                        <p/>
                        <p>Прежде всего, понимание сути ресурсосберегающих технологий способствует подготовке
                            и реализации анализа существующих паттернов поведения.
                            Как принято считать, стремящиеся вытеснить традиционное производство,
                            нанотехнологии представляют собой не что иное, как квинтэссенцию победы маркетинга
                            над разумом и должны быть разоблачены.
                            Кстати, акционеры крупнейших компаний в равной степени предоставлены сами себе.</p>
                        <p>Являясь всего лишь частью общей картины, тщательные исследования конкурентов разоблачены.
                            И нет сомнений, что непосредственные участники технического прогресса подвергнуты целой
                            серии независимых исследований.</p>
                        <p>Внезапно, явные признаки победы институциализации формируют глобальную экономическую сеть
                            и при этом - рассмотрены исключительно в разрезе маркетинговых и финансовых предпосылок.
                            Таким образом, граница обучения кадров предопределяет высокую востребованность
                            экономической целесообразности принимаемых решений.</p>
                    </StyledReviews.Content>
                </StyledReviews.Content>
            </div>
        </>
    );
};

export default Reviews;
