import styled from 'styled-components';

const StyleProfile = {
    Row: styled.div`
        display: flex;
        width: 100%;
        column-gap: 50px;
      
        @media (max-width: 840px) {
          flex-direction: column;
          align-items: center;
        }
    `,
    Wrap: styled.div``,
    Loading: styled.h1``,
    Info: styled.div`
        input {
          max-width: 500px;
        }

        @media (max-width: 840px) {
          margin-bottom: 20px;
        }
    `,
    Lists: styled.div`
        flex: 1;
    `,
    Packs: styled.div`
        width: 400px;
        margin-bottom: 20px;

        @media (max-width: 840px) {
            width: 345px;
        }

        @media (max-width: 370px) {
            width: 260px;
        }
    `,
    Item: styled.div<{center?: boolean}>`
        text-align: left;
        ${p => p.center && 'text-align: center;'};
    `,
    Records: styled.div<{textAlign?: boolean}>`
        flex: 2;
        ${p => p.textAlign && 'text-align: center'};
        @media (min-width: 840px) {
            border-left: 0.0625rem solid #e6e6e6;
            padding-left: 50px;
        }
        @media (max-width: 840px) {
            flex: 1;
        }
    `,
    ActiveRecords: styled.div``,
    PastRecords: styled.div``,
    RecordItem: styled.div`
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        border: 0.0625rem solid #e6e6e6;
        border-radius: 8px;
        padding: 10px;
    `,
}

export const RecordItem = {
    DateTime: styled.div`
        display: flex;
        justify-content: space-between;
        border-bottom: 0.0625rem solid #e6e6e6;
        padding-bottom: 10px;
        margin-bottom: 10px;
    `,
    Activity: styled.div`
        font-size: 18px;
        font-weight: 600;
    `,
    Coach: styled.div`
        margin-bottom: 10px;
    `,
    State: styled.div``,
    Row: styled.div`
      display: flex;
      justify-content: space-between;
      align-items: center;
    `
}

export default StyleProfile