import styled from 'styled-components';

const MobileMenuWrap = styled.div<{ open: boolean}>`
    display: none;
    position: absolute;
    overflow: hidden;
    right: 0;
    top: 0;
    background-color: #000;
    width: 0;
    height: 100vh;
    color: #000;
    z-index: 1;
    padding-top: 6.25rem;
    text-align: center;
    font-size: 1.25rem;
    -webkit-transition: width .3s, color .3s;
    transition: width .3s, color .3s;
    
    @media (max-width: 960px) {
        display: block;
    }
    
    ${({open}) => open && `
        width: 50vw;
        color: white;
        
        @media (max-width: 640px) {
            width: 100vw;
        }
    `}
`

const ButtonCallback = styled.button`
    display: block;
    padding-bottom: 0.1875rem;
    border-bottom: 0.0625rem dashed rgba(0,0,0,0.3);
    -webkit-transition: border-color .2s;
    
    color: inherit;
    background-color: inherit;
    font-size: inherit;
    width: 100%;
    transition: color 200ms ease-in-out;
    
    &:hover {
        color: #DF0F0F;
    }
`

export {MobileMenuWrap, ButtonCallback}