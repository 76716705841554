import styled from 'styled-components';

type ButtonProps = {
    disabled?: boolean
    transparent?: boolean
    colored?: string
    padding?: string
    fontSize?: number
    marginBottom?: number
}

const Button = styled.button<ButtonProps>`
    background-color: ${({colored}) => colored && `${colored}`};
    background: ${({transparent}) => transparent && 'transparent'};
    color: white;
    font-size: ${({fontSize}) => fontSize ? `${fontSize}px` : '1rem'};
    font-weight: 600;
    padding: ${({padding}) => padding ? padding : '10px 15px'};
    border: 0.125rem solid ${({colored}) => colored ? colored : 'white'};
    border-radius: 3.125rem;
    transition: background-color 0.25s ease-out,color 0.25s ease-out;
    margin-bottom: ${({marginBottom}) => marginBottom !== undefined ? `${marginBottom}px` : '10px'};;
  
    ${p => p.disabled && 'opacity: 0.5;'}
    
    &:hover {
        background-color: ${({colored}) => colored && 'transparent'};
        color: ${({colored}) => colored};
        background-color: ${({transparent}) => transparent && 'red'};
        border: 0.125rem solid ${({colored}) => colored ? colored : 'red'};
    }
    
    @media (max-width: 640px) {
        font-size: .75rem;
        margin: 0 0 0.9375rem 0;
    }
`

export default Button;
