import styled from 'styled-components';

const BreadcrumbsItem = styled.li`
  font-size: .6875rem;
  color: #000;
  cursor: default;
  text-transform: uppercase;
  
  &:not(:last-child)::after {
    content: '/';
    margin: 0 0.75rem;
    color: #cacaca;
  }
`

export default BreadcrumbsItem;
