import React, {FC, useCallback, useState} from 'react';
import Button from '../styles/components/Button';
import Form from '../styles/components/Form';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {useSendFeedbackMutation} from '../services/FeedbackService';
import {useAppDispatch} from '../redux/store';
import {setPopup} from '../redux/slices/uiSlice';
import {ActionCreatorWithoutPayload} from '@reduxjs/toolkit';

export enum CallbackFormType {
    QUESTIONS = 'QUESTIONS',
    RECORD = 'RECORD'
}

export type CallbackFormProps = {
    type: CallbackFormType.QUESTIONS | CallbackFormType.RECORD,
    close?: ActionCreatorWithoutPayload<string>
}

const CallbackForm: FC<CallbackFormProps> = ({type, close}) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [sendFeedback] = useSendFeedbackMutation()
    const dispatch = useAppDispatch()

    let title = ''
    let subTitle = ''
    let subject = ''

    switch(type) {
        case CallbackFormType.QUESTIONS:
            title = 'Остались вопросы?'
            subTitle = 'Задайте их администратору студии +7(999)979-79-31 или оставьте контакты и мы перезвоним Вам!'
            subject = 'Заказать звонок'
            break
        case CallbackFormType.RECORD:
            title = 'Запись на тренировку'
            subTitle = 'Чтобы записаться на тренировку, заполните поля формы:'
            subject = 'Записаться на стартовую тренировку'
            break
    }

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');

    const handleReCaptchaVerify = useCallback(async (e?: React.MouseEvent) => {
        if (e) e.preventDefault()
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const token = await executeRecaptcha();

        const res = await sendFeedback({
            id: 138,
            name,
            phone,
            token,
            subject
        }).unwrap()

        if (res.result) {
            dispatch(setPopup({
                type: 'success',
                text: 'Информация отправлена успешно'
            }))
            if (close) dispatch(close())
        }

    }, [executeRecaptcha,  close, dispatch, name, phone, sendFeedback, subject]);

    return (
        <Form.Wrap>
            <h2>{title}</h2>
            <Form.Text>{subTitle}</Form.Text>
            <Form.Row>
                <div>
                    <label>
                        Ваше имя (обязательно)
                        <input type='text' value={name} onChange={e => setName(e.target.value)}/>
                    </label>
                </div>
                <div>
                    <label>
                        Телефон (обязательно)
                        <input type='text' value={phone} onChange={e => setPhone(e.target.value)}/>
                    </label>
                </div>
                <Button colored={'#DF0F0F'} onClick={handleReCaptchaVerify}>Перезвоните мне</Button>
            </Form.Row>
        </Form.Wrap>
    );
};

export default CallbackForm;
