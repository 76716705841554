import {createSlice} from '@reduxjs/toolkit';
import {PopupType} from '../../types';

export interface uiSliceState {
    mobMenuOpen: boolean
    modalQuestions: boolean
    modalRecord: boolean
    modalLogin: boolean
    popup: PopupType
}

const initialState: uiSliceState = {
    mobMenuOpen: false,
    modalQuestions: false,
    modalRecord: false,
    modalLogin: false,
    popup: {
        isOpen: false,
        text: '',
        type: 'info'
    }
}

export const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        toggleMobMenu(state) {
            state.mobMenuOpen = !state.mobMenuOpen
        },
        toggleModalQuestions(state) {
            state.modalQuestions = !state.modalQuestions
        },
        toggleModalRecord(state) {
            state.modalRecord = !state.modalRecord
        },
        toggleModalLogin(state) {
            state.modalLogin = !state.modalLogin
        },
        setPopup(state, action) {
            const newPopup: PopupType = {
                isOpen: !state.popup.isOpen
            }

            if (action.payload.text) newPopup.text = action.payload.text
            if (action.payload.type) newPopup.type = action.payload.type

            state.popup = newPopup
        }
    },
})

export const {toggleMobMenu, toggleModalQuestions, toggleModalRecord, toggleModalLogin, setPopup} = uiSlice.actions

export default uiSlice.reducer