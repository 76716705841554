import styled from 'styled-components';

type TabType = {
    active?: boolean
}

const StyledLoginForm = {
    TabContainer: styled.div`
        display: flex;
        justify-content: space-around;
    `,
    Tab: styled.h2<TabType>`
        cursor: pointer;
        ${p => !p.active && 'opacity: 0.5;'}
    `
}

export default StyledLoginForm

