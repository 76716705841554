import React, {FC} from 'react';
import {Outlet} from 'react-router-dom';
import Socials from '../components/Socials';
import CallbackForm, {CallbackFormType} from '../components/CallbackForm';

const SocialsAndCallback: FC = () => {
    return (
        <div>
            <Outlet/>
            <Socials/>
            <CallbackForm type={CallbackFormType.QUESTIONS}/>
        </div>
    );
};

export default SocialsAndCallback;
