import {FC} from 'react';
import {Link, Location} from 'react-router-dom';
import {BreadcrumbsItem, BreadcrumbsWrapper} from '../styles/components';

type BreadcrumbsPropType = {
    location: Location;
    title: string;
}

export const Breadcrumbs: FC<BreadcrumbsPropType> = ({location, title}) => {
    const { pathname } = location;


    const pathnames = pathname.split('/').filter((el: string) => el)

    return (
        <BreadcrumbsWrapper>
            <BreadcrumbsItem><Link to='/'>Главная</Link></BreadcrumbsItem>
            {pathnames.map((pathname: string, index: number) => {
                const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
                const isLast = index === pathnames.length - 1;
                return isLast ? (
                    <BreadcrumbsItem key={index}><Link key={index} to={routeTo}>{title}</Link></BreadcrumbsItem>
                ) : null;
            })}
        </BreadcrumbsWrapper>
    )
}
