import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {PackType} from '../types';


export const packsApi = createApi({
    reducerPath: 'packsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_HOST + '/gym',
        mode: 'cors',
    }),
    endpoints: (build) => ({
        getPacks: build.query<PackType[], { id: number, token: string }>({
            query: ({id, token}) => {
                return {
                    url: `/${id}/packs`,
                    params: {
                        token
                    }
                }
            },
        })
    })
});

export const {useGetPacksQuery} = packsApi

