import React, {FC} from 'react';
import {ListItem} from '../styles/components';

type ListType = {
    items: Array<string>;
}

export const List: FC<ListType> = ({items}) => {
    return (
        <ul>
            {items.map((item: string, index) => (
                    <ListItem key={index} marker='disc'>{item}</ListItem>
                )
            )}
        </ul>
    )
}
