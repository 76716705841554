import {FC, useCallback, useEffect, useState, memo} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import useDocumentTitle from '../utils/useDocumentTitle';
import {Breadcrumbs} from '../components';
import {
    useGetScheduleQuery,
    useLazyCancelTrainingQuery,
    useLazySignUpTrainingQuery
} from '../services/ScheduleServices';
import {useSelector} from 'react-redux';
import {RootState, useAppDispatch} from '../redux/store';
import ScheduleCard from '../components/ScheduleCard';
import moment from 'moment';
import 'moment/locale/ru';
import {Swiper as SwiperClass} from 'swiper/types';
import StyledSwiperSchedule from '../styles/StyledSwiperSchedule';
import ModalDialog from '../components/ModalDialog';
import {setPopup, toggleModalLogin} from '../redux/slices/uiSlice';
import {GroupByDateScheduleType, RecordType, ScheduleType} from '../types';
import {useGetRecordsQuery} from '../services/RecordsService';

const Schedule: FC = () => {
    const {token, isAuth} = useSelector((state: RootState) => state.auth)
    const startWeek = moment().startOf('week').format('YYYY-MM-DD');
    const endWeek = moment().endOf('week').format('YYYY-MM-DD');
    const {data} = useGetScheduleQuery({id: 138, token: token, fromDate: startWeek, toDate: endWeek});
    const {data: userRecords} = useGetRecordsQuery({id: 138, token: token, inpast: 0});
    const [signUpTraining] = useLazySignUpTrainingQuery();
    const [cancelTraining] = useLazyCancelTrainingQuery();
    const [trainingsGroups, setTrainingsGroups] = useState<ScheduleType[][]>([]);
    const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);
    const [isVisibleCancelModal, setIsVisibleCancelModal] = useState<boolean>(false);
    const [isVisibleHasActivePacksModal, setIsVisibleHasActivePacksModal] = useState<boolean>(false);
    const [currentTrainingId, setCurrentTrainingId] = useState<number>(0);
    const [currentTrainingDate, setCurrentTrainingDate] = useState<string>('');
    const [isLoadingCancelTraining, setIsLoadingCancelTraining] = useState<boolean>(false);
    const [swiperRef, setSwiperRef] = useState<SwiperClass>();
    const location = useLocation();
    const navigate = useNavigate();
    const currentDate = moment(new Date()).format('YYYY-MM-DD');
    const dispatch = useAppDispatch();

    useDocumentTitle('Расписание | Искусство бокса', true);

    useEffect(() => {
        const groups = data?.tt.reduce((groups: GroupByDateScheduleType, item: ScheduleType) => {
            const date = item['activitydate'];
            groups[date] = (groups[date] || []).concat(item);
            return groups;
        }, {});
        if (typeof groups !== 'undefined') {
            setTrainingsGroups(Object.values(groups));
        }
    }, [data]);
    const prevPage = useCallback(() => {
        if (swiperRef) swiperRef.slidePrev()
    }, [swiperRef])

    const nextPage = useCallback(() => {
        if (swiperRef) swiperRef.slideNext()
    }, [swiperRef])

    const signUpTrainingHandle = (id: number, trainingDate: string, isRecorded: boolean) => {
        if (isRecorded) {
            setCurrentTrainingId(id);
            setIsVisibleCancelModal(true);
            return;
        }
        setCurrentTrainingId(id);
        setIsVisibleModal(true);
        setCurrentTrainingDate(trainingDate);
    }

    const resetTraining = () => {
        setCurrentTrainingId(0);
        setCurrentTrainingDate('');
    }

    const toggleModals = (isShowPackModal: boolean, isShowSignUpTrainingModal: boolean) => {
        if (isShowPackModal && isShowSignUpTrainingModal) return;
        setIsVisibleModal(isShowSignUpTrainingModal);
        setIsVisibleHasActivePacksModal(isShowPackModal);
    }

    const onConfirmSignUpTraining = useCallback(async () => {
        if (!token.length) {
            // setCurrentTrainingId(0);
            // setCurrentTrainingDate('');
            dispatch(toggleModalLogin());
            return;
        }
        const {data: signedData, error: signedError} =
            await signUpTraining({
                id: 138,
                trainingId: currentTrainingId,
                token: token,
                trainingDate: currentTrainingDate
            });

        if (signedError) {
            toggleModals(false, false);
            resetTraining();
            dispatch(setPopup({
                type: 'error',
                text: 'Произошла ошибка при записи на тренировку'
            }));
        }

        if (signedData && signedData.result !== 0) {
            toggleModals(false, false);
            resetTraining();
            dispatch(setPopup({
                type: 'error',
                text: signedData.message
            }));
            return;
        }
        toggleModals(false, false);
        dispatch(setPopup({
            type: 'success',
            text: 'Вы успешно записались на занятие'
        }));
        window.location.reload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTrainingId, currentTrainingDate, token, dispatch])

    useEffect(() => {
        if (currentTrainingId) onConfirmSignUpTraining().then()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth])

    const onCancelSignUpTraining = () => {
        setIsVisibleModal(false);
        setCurrentTrainingId(0);
    }

    const onConfirmCancelTraining = useCallback(async () => {
        setIsLoadingCancelTraining(true)

        let record: RecordType = {} as RecordType;

        console.log('record:', record);

        if (typeof userRecords === 'undefined') {
            dispatch(setPopup({
                type: 'error',
                text: 'Не удалось отменить запись на тренировку, попробуйте еще раз'
            }));
            setCurrentTrainingId(0);
            setIsVisibleCancelModal(false);
            return;
        }

        record = userRecords.records.filter((record) => record.schedule_id === currentTrainingId)[0];

        const {data, error} = await cancelTraining({recordId: record.id, token: token});

        setIsLoadingCancelTraining(false)

        if (error) {
            dispatch(setPopup({
                type: 'error',
                text: 'Не удалось отменить запись на тренировку, попробуйте еще раз'
            }));
            setIsVisibleCancelModal(false);
            setCurrentTrainingId(0);
            return;
        }

        if (data && data.result === false) {
            dispatch(setPopup({
                type: 'error',
                text: data.message
            }));
            setIsVisibleCancelModal(false);
            setCurrentTrainingId(0);
            return;
        }

        if (data && data.result === true) {
            dispatch(setPopup({
                type: 'success',
                text: 'Вы отменили запись на тренировку'
            }));
            setIsVisibleCancelModal(false);
            setCurrentTrainingId(0);
            window.location.reload();
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userRecords, currentTrainingId, token]);

    const onConfirmBuyPacks = () => {
        navigate('/products');
    };
    const onCancelBuyPacks = () => {
        setIsVisibleHasActivePacksModal(false);
    }

    const onDeclineCancelTraining = () => {
        setIsVisibleCancelModal(false);
    }

    const checkRecord = (training: ScheduleType, records?: Array<RecordType>) => {
        if (records) {
            return records.some(record => record.coach === training.coach &&
            record.activitydate === training.activitydate &&
            record.starttime === training.start &&
            record.endtime === training.end &&
            (record.deleted === null || record.deleted === 0));
        }
        return false;
    }

    return (
        <>
            <Breadcrumbs
                location={location}
                title='Расписание'
            />
            <h1>Расписание</h1>
            <div>
                <StyledSwiperSchedule.Wrapper>
                    <StyledSwiperSchedule.Button arrowwidth={63} rotate={180} onClick={prevPage}/>
                    <StyledSwiperSchedule.Swiper
                        onSwiper={setSwiperRef}
                        breakpoints={{
                            240: {
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 2,
                            },
                            1024: {
                                slidesPerView: 5,
                            }
                        }}
                    >
                        {trainingsGroups.map((group: Array<ScheduleType>, index: number) => {
                            return <StyledSwiperSchedule.SwiperSlide key={index}>
                                <div>
                                    <StyledSwiperSchedule.Date
                                        isCurrentDay={moment(currentDate).isSame(group[0].activitydate)}
                                    >{
                                        moment(group[0].activitydate)
                                            .locale('ru')
                                            .format('dd')
                                            .charAt(0)
                                            .toUpperCase() +
                                        moment(group[0].activitydate)
                                            .locale('ru')
                                            .format('dd')
                                            .slice(1)
                                    }</StyledSwiperSchedule.Date>
                                    <StyledSwiperSchedule.Date
                                        isCurrentDay={moment(currentDate).isSame(group[0].activitydate)}
                                    >
                                        {moment(group[0].activitydate).format('D.MM')}
                                    </StyledSwiperSchedule.Date>
                                </div>
                                {group.map((training) => (
                                    <ScheduleCard
                                        key={training.id}
                                        id={training.id}
                                        startTime={training.start}
                                        endTime={training.end}
                                        trainer={training.coach}
                                        isAvaiable={training.avaiable === 0}
                                        trainingName={training.name}
                                        trainingDate={training.activitydate}
                                        hasRecord={checkRecord(training, userRecords?.records)}
                                        onClick={(id, trainingDate, isRecorded) =>
                                            signUpTrainingHandle(id, trainingDate, isRecorded)}
                                    />
                                ))}
                            </StyledSwiperSchedule.SwiperSlide>
                        })}
                    </StyledSwiperSchedule.Swiper>
                    <StyledSwiperSchedule.Button arrowwidth={63} onClick={nextPage}/>
                </StyledSwiperSchedule.Wrapper>
            </div>
            {isVisibleModal ? (
                <ModalDialog
                    title='Запись на тренировку'
                    text='Вы точно хотите записаться на тренировку?'
                    confirmButtonText='Да'
                    cancelButtonText='Нет'
                    onConfirm={onConfirmSignUpTraining}
                    onCancel={onCancelSignUpTraining}
                />
            ): null}
            {isVisibleHasActivePacksModal ? (
                <ModalDialog
                    title='Запись на тренировку'
                    text='У вас нет оплаченных тренировок на балансе. Хотите приобрести?'
                    confirmButtonText='Купить'
                    cancelButtonText='Нет, спасибо'
                    onConfirm={onConfirmBuyPacks}
                    onCancel={onCancelBuyPacks}
                />
            ): null}
            {isVisibleCancelModal ? (
                <ModalDialog
                    title='Отмена записи'
                    text='Вы уверены, что хотите отменить запись?'
                    confirmButtonText={ isLoadingCancelTraining ? 'Отменяем запись...' : 'Да'}
                    cancelButtonText='Нет'
                    onConfirm={onConfirmCancelTraining}
                    onCancel={onDeclineCancelTraining}
                />
            ): null}
        </>
    );
};

export default memo(Schedule);
