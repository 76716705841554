import styled from 'styled-components';

const ArticlePage = styled.div`
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row wrap;

  @media (max-width: 546px) {
    flex-direction: column;
    align-items: center;
  }
`

export default ArticlePage;
