import React, {FC} from 'react';
import {Map} from '../styles/Map'
import {Breadcrumbs} from '../components';
import {useLocation} from 'react-router-dom';
import useDocumentTitle from '../utils/useDocumentTitle';


const Contacts: FC = () => {
    const location = useLocation();

    useDocumentTitle('Контакты | Искусство бокса', true);

    return (
        <>
            <Breadcrumbs
                location={location}
                title='Контакты'
            />
            <h1>Контакты</h1>

            <h2>Реквизиты</h2>
            <p>ФИО: Аветисян Артур Робертович</p>
            <p>ИНН: 773714074575</p>
            <p>ОГРНИП: 319774600324228</p>

            <h2>Телефон</h2>
            <p><a href='tel:+79999797931'>+7 (999) 979-79-31</a></p>

            <h2>Почтовый адрес</h2>
            <p>115088</p>

            <h2>Эл. почта</h2>
            <p><a href='mailto:artboxing@bk.ru'>artboxing@bk.ru</a></p>
        </>
    );
};

export default Contacts;
