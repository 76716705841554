import React, {FC, useEffect, useState} from 'react';
import {Breadcrumbs} from '../components';
import {useLocation} from 'react-router-dom';
import {ListItem} from '../styles/components';
import useDocumentTitle from '../utils/useDocumentTitle';


type MapItemType = {
    name: string;
    href: string;
}

const SiteMap: FC = () => {
    const location = useLocation();

    useDocumentTitle('Карта сайта | Искусство бокса', true);

    const [mapItems, setMapItems] = useState<Array<MapItemType>>([]);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            setMapItems([
                {
                    name: 'Главная',
                    href: '/'
                },
                {
                    name: 'Направления',
                    href: '/destinations'
                },
                {
                    name: 'Промо-акции',
                    href: '/auction'
                },
                {
                    name: 'Цены',
                    href: '/prices'
                },
                {
                    name: 'Расписание',
                    href: '/schedule'
                },
                {
                    name: 'Отзывы',
                    href: '/reviews'
                },
                {
                    name: 'О нас',
                    href: '/about'
                },
                {
                    name: 'Контакты',
                    href: '/contacts'
                },
                {
                    name: 'Тренеры',
                    href: '/coaches'
                },
                {
                    name: 'Политика конфиденциальности',
                    href: '/privacy_statement'
                },
            ]);
        }
        return () => {
            mounted = false;
        };
    }, []);


    return (
        <>
            <Breadcrumbs
                location={location}
                title='Карта сайта'
            />
            <h1>Карта сайта</h1>
            <ul>
                {mapItems.map((item, index) => (
                    <ListItem key={index} marker='disc'>
                        <a href={item.href}>{item.name}</a>
                    </ListItem>
                ))}
            </ul>
            <div>
            </div>
        </>
    );
};

export default SiteMap;
