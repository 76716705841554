import styled from 'styled-components';
import Background from '../assets/main/bg1.jpg';

const MainWrapper = styled.div`
    background-image: url(${Background});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-bottom: 15rem;
    
    @media (max-width: 640px) {
        background-size: cover;
        padding-bottom: 15.75rem;
        background-position: 0 -1.875rem;
    }
`

const Note = styled.div`
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    display: flex;
    justify-content: flex-end;
    
    button {
        @media (max-width: 960px) {
            width: 100%;
        }
    }
`

export {MainWrapper, Note}