import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {RecordType} from '../types';

type GetRecordsType = {
    id: number,
    token: string,
    inpast: number
}

type GetRecordsResultType = {
    pos: number
    result: number
    total_count: number
    records: RecordType[]
}

export const recordsApi = createApi({
    reducerPath: 'recordsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_HOST + '/scheduler/records/gym',
        mode: 'cors',
    }),
    endpoints: (build) => ({
        getRecords: build.query<GetRecordsResultType, GetRecordsType>({
            query: ({id, token, inpast}) => ({
                url: `/${id}`,
                params: {
                    token,
                    inpast: inpast,
                    sort: 'asc',
                    limit: 10,
                    start: 0
                }
            }),
        }),
    })
});

export const {useGetRecordsQuery} = recordsApi
