import styled from 'styled-components';
import {Swiper, SwiperSlide} from 'swiper/react';
import {ReactComponent as arrowNext} from '../assets/about/arrow-next.svg'

type ButtonProps = {
    rotate?: number
    arrowwidth: number
}

type DatePropsType = {
    isCurrentDay: boolean;
}

const StyledSwiperSchedule = {
    Date: styled.p<DatePropsType>`
      margin-bottom: 0.5rem;
      color: ${({isCurrentDay}) => isCurrentDay && '#DF0F0F'}
    `,
    Wrapper: styled.div`
        display: flex;
        justify-content: space-between;
    `,
    Button: styled(arrowNext)<ButtonProps>`
        ${p => p.rotate && `transform: rotate(${p.rotate}deg);`}
        width: ${p => p.arrowwidth && `${p.arrowwidth}px;`}
        cursor: pointer;
        margin: 0;

        @media screen  and (max-width: 768px) {
          align-self: flex-start;
          width: 40px;
        }
    `,
    Swiper: styled(Swiper)`
        width: 100%;
        height: 100%;
    `,
    SwiperSlide: styled(SwiperSlide)`
        text-align: center;
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 3px;
    `
}

export default StyledSwiperSchedule
