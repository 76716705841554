import React, {FC, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import {RootState, useAppDispatch} from '../redux/store';
import {useSelector} from 'react-redux';
import {toggleMobMenu, toggleModalLogin, toggleModalQuestions} from '../redux/slices/uiSlice';
import {ButtonCallback} from '../styles/MobileMenu';
import {useCookies} from 'react-cookie';

const elements = [
    {
        path: '/destinations',
        text: 'Направления'
    },
    {
        path: '/auction',
        text: 'Промо-акции'
    },
    {
        path: '/schedule',
        text: 'Расписание'
    },
    {
        path: '/products',
        text: 'Продукты'
    },
    {
        path: '/about',
        text: 'О нас'
    },
    {
        path: '/contacts',
        text: 'Контакты'
    },
    {
        path: '/coaches',
        text: 'Тренеры'
    }
]

const MobileMenu: FC = () => {
    const {mobMenuOpen} = useSelector((state: RootState) => state.ui)
    const [, , removeCookie] = useCookies(['token'])
    const {isAuth} = useSelector((state: RootState) => state.auth)
    const dispatch = useAppDispatch()

    const mobMenuRef = useRef<HTMLDivElement>(null);

    const exitClickHandler = () => {
        removeCookie('token')
        window.location.reload()
    }

    const clickHandler = (/*e: MouseEvent*/) => {
        // const path = e.composedPath()
        //
        // const isOpen = store.getState().mobMenu.open
        // if (path && !path.includes(mobMenuRef.current!) && isOpen) {
        //     dispatch(toggleOpen())
        // }
    }

    useEffect(() => {
        let mounted = true

        if (mounted) {
            document.addEventListener('click', clickHandler)
        }

        return () => {
            mounted = false
            document.removeEventListener('click', clickHandler)
        }
    }, [mobMenuOpen])

    return (
        <div className={'mobileMenuWrap ' + (mobMenuOpen ? 'open' : '')} ref={mobMenuRef}>
            <ul>
                {elements.map((element, index) =>
                    <li key={index}>
                        <Link to={element.path} onClick={() => dispatch(toggleMobMenu())}>{element.text}</Link>
                    </li>
                )}
                <li>
                    <a href='tel:+79999797931' title='Позвонить'>+7 (999) 979-79-31</a>
                </li>
                <li>
                    <ButtonCallback
                        onClick={() => dispatch(toggleModalQuestions())}
                        data-open='order-callback'
                        aria-controls='order-callback'
                        aria-haspopup='true'
                        tabIndex={0}
                    >
                        Заказать звонок
                    </ButtonCallback>
                </li>
                <li><br/></li>
                <li>
                    {isAuth ?
                        <>
                            <Link
                                to={'/profile'}
                                onClick={() => dispatch(toggleMobMenu())}
                            >Кабинет</Link>
                            <br/>
                            <Link to={'/profile'} onClick={exitClickHandler}>Выйти</Link>

                        </>
                        : <ButtonCallback
                            onClick={() => dispatch(toggleModalLogin())}
                        >
                            Войти
                        </ButtonCallback>
                    }
                </li>
            </ul>
        </div>
    );
};

export default MobileMenu;
