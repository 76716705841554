import styled from 'styled-components';
import {Link} from 'react-router-dom';

const StyledHeader = {
    Row: styled.div`
      display: flex;
      justify-content: space-between;
      align-items: center;
    `,
    Logo: styled(Link)`

      svg {
        @media (max-width: 640px) {
          width: 9.375rem;
          height: auto;
        }
      }
    `,
    Nav: styled.nav`
      display: flex;
      justify-content: space-between;
      column-gap: 60px;

      @media (max-width: 1080px) {
        column-gap: 26px;
      }
    `,
    List: styled.ul`
      display: flex;
      justify-content: space-between;
      font-size: 1.125rem;
      column-gap: 15px;

      @media (max-width: 100px) {
        column-gap: 5px;
      }

      @media (max-width: 960px) {
        display: none;
      }
    `,
    ListElement: styled.li`
      a {
        &:hover {
          color: #DF0F0F;
        }
      }
    `,
    Call: styled.div`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    `,
    Num: styled.a`
      font-weight: 600;

      &:hover {
        color: #DF0F0F;
      }
    `,
    Callback: styled.button`
      font-size: .75rem;
      border-bottom: 0.0625rem dashed rgba(0, 0, 0, 0.3);
      background: transparent;

      &:hover {
        border-bottom: 0.0625rem dashed rgba(0, 0, 0, 0.7);
      }
    `,
    Extra: styled.div`
      display: flex;
      column-gap: 15px;
      
      i {
        position: relative;
        top: 3px;
        cursor: pointer;
      }

      @media (max-width: 960px) {
        display: none;
      }
    `
}

export default StyledHeader
