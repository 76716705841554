import React, {FC} from 'react';
import ModalCallbackForm from './ModalCallbackForm';
import {CallbackFormType} from './CallbackForm';
import ModalLoginForm from './ModalLoginForm';
import {useSelector} from 'react-redux';
import {RootState} from '../redux/store';
import Popup from './Popup';

const Modals: FC = () => {
    const {modalQuestions, modalRecord, modalLogin} = useSelector((state: RootState) => state.ui)

    return (
        <>
            {modalQuestions && <ModalCallbackForm type={CallbackFormType.QUESTIONS}/>}
            {modalRecord && <ModalCallbackForm type={CallbackFormType.RECORD}/>}
            {modalLogin && <ModalLoginForm/>}
            <Popup/>
        </>
    );
};

export default Modals;
