import React, {FC} from 'react';
import {Column, useTable} from 'react-table';
import {StyledTable} from '../styles/components';

type TablePropsType = {
    columns: Array<Column>;
    data: Array<object>;
}

export const Table: FC<TablePropsType> = ({columns, data}) => {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data
    })

    return (
        <StyledTable.Wrapper {...getTableProps()}>
            <StyledTable.Header>
            {headerGroups.map(headerGroup => (
                <StyledTable.Row {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <StyledTable.HeaderRow {...column.getHeaderProps()}>
                            {column.render('Header')}
                        </StyledTable.HeaderRow>
                    ))}
                </StyledTable.Row>
            ))}
            </StyledTable.Header>
            <StyledTable.Body {...getTableBodyProps()}>
            {rows.map((row) => {
                prepareRow(row);
                return (
                    <StyledTable.Row {...row.getRowProps()}>
                        {row.cells.map(cell => {
                            return <StyledTable.Cell {...cell.getCellProps()}>{cell.render('Cell')}</StyledTable.Cell>
                        })}
                    </StyledTable.Row>
                )
            })}
            </StyledTable.Body>
        </StyledTable.Wrapper>
    )
}
