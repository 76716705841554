import React, {FC, useRef} from 'react';
import Modal from '../styles/components/Modal';
import LoginForm from './LoginForm';
import {toggleModalLogin} from '../redux/slices/uiSlice';
import {useAppDispatch} from '../redux/store';

const ModalLoginForm: FC = () => {
    const dispatch = useAppDispatch()
    const bgRef = useRef(null);

    return (
        <Modal.Background
            onClick={(e) => {
                if (e.target === bgRef.current) dispatch(toggleModalLogin())
            }}
            ref={bgRef}
        >
            <Modal.Wrapper>
                <LoginForm/>
            </Modal.Wrapper>
        </Modal.Background>
    );
};

export default ModalLoginForm;
