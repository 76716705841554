import styled from 'styled-components';

const Form = {
    Wrap: styled.form`
        width: 58.3%;
        margin-bottom: 1.875rem;
        
        @media (max-width: 960px) {
            width: 100%;
        }
    `,
    Text: styled.div`
        font-size: 1.5rem;
        margin-bottom: 1.25rem;
        line-height: 1.1875;
    `,
    Row: styled.div`
        button {
            @media (max-width: 960px) {
                width: 100%;
            }
        }
    `
}

export default Form