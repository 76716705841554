import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {CategoriesType, ProductType} from '../types';

type GetProductsType = {
    id: number
}

type GetCategoriesType = {
    id: number
}

type GetCategoriesResultType = {
    result: boolean
    data: CategoriesType[]
}

export const productsApi = createApi({
    reducerPath: 'productsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_HOST + '/gym',
        mode: 'cors',
    }),
    endpoints: (build) => ({
        getProducts: build.query<ProductType[], GetProductsType>({
            query: ({id}) => ({
                url: `/${id}/products`
            }),
        }),
        getCategories: build.query<CategoriesType[], GetCategoriesType>({
            query: ({id}) => ({
                url: `/${id}/products/categories`,
            }),
            transformResponse: (res: GetCategoriesResultType) => res.data
        })
    })
});

export const {useGetProductsQuery, useGetCategoriesQuery} = productsApi
