import styled from 'styled-components';

type StyledScheduleCardPropsType = {
    isDisabled: boolean;
    IsAvaiable: boolean;
    hasRecord: boolean;
}

const StyledScheduleCard = {
    Wrapper: styled.button<StyledScheduleCardPropsType>`
        display: flex;
        flex-direction: column;
        padding: 7px;
        margin: 3px 0;
        border: 0.5px solid #d5d7d8;
        border-radius: 8px;
        pointer-events: ${({isDisabled, IsAvaiable, hasRecord }) => 
        (isDisabled  && !hasRecord) || (IsAvaiable && !hasRecord)  ? 'none' : 'auto'};
        cursor: pointer;
        background-color: ${({hasRecord, IsAvaiable}) => {
            if (hasRecord) {
              return '#DCDCDC';
            }
            if (IsAvaiable) {
              return '#A9A9A9'
            }
            return  '#FFFFFF'
        }};
        @media screen and (max-width: 768px) {
          width: 100%;
        }
        @media screen and (min-width: 768px) and (max-width: 1024px) {
          width: 99%;
        }
    `,
    Content: styled.div`
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    `,
    Title: styled.p<StyledScheduleCardPropsType>`
      font-size: 14px;
      margin-bottom: 0;
      font-weight: bold;
      color: ${({isDisabled}) => {
          if (isDisabled) {
            return '#8c8c8c'
          }
          return '#000000'
      }};
    `,
    TrainerName: styled.p<StyledScheduleCardPropsType>`
      font-size: 14px;
      margin-bottom: 0;
      text-align: left;
      color: ${({isDisabled}) => {
        if (isDisabled) {
          return '#8c8c8c'
        }
        return '#000000'
      }};
    `,
    RecordedLabel: styled.p`
      font-size: 12px;
      color: red;
      margin-bottom: 0;
    `,
    Time: styled.p<StyledScheduleCardPropsType>`
      font-size: 14px;
      margin-bottom: 0;
      color: ${({isDisabled}) => {
        if (isDisabled) {
          return '#8c8c8c'
        }
        return '#000000'
      }};
    `,
}

export default StyledScheduleCard;
