import styled from 'styled-components';
import {Swiper, SwiperSlide} from 'swiper/react';
import {ReactComponent as arrowNext} from '../assets/about/arrow-next.svg'

type ButtonProps = {
    rotate?: number
    arrowwidth: number
}

const StyledSwiper = {
    Wrapper: styled.div`
        display: flex;
        justify-content: space-between;
    `,
    Button: styled(arrowNext)<ButtonProps>`
        ${p => p.rotate && `transform: rotate(${p.rotate}deg);`}
        width: ${p => p.arrowwidth && `${p.arrowwidth}px;`}
        cursor: pointer;

      @media(max-width: 700px) {
        width: 30px;
      }
    `,
    Swiper: styled(Swiper)`
        width: 100%;
        height: 100%;
    `,
    SwiperSlide: styled(SwiperSlide)`
        text-align: center;
        font-size: 18px;
        background: #fff;
      
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    
        img {
            display: block;
            width: 550px;
            object-fit: cover;

          @media(max-width: 700px) {
            width: 300px;
            height: 400px;
          }

          @media(max-width: 370px) {
            width: 210px;
            height: 280px;
          }
        }
    `
}

export default StyledSwiper