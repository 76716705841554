import React, {FC, useEffect, useMemo, useState} from 'react';
import {Breadcrumbs} from '../components';
import {useLocation} from 'react-router-dom';
import {Table} from '../components/Table';
import servicesJSON from '../assets/services.json';
import useDocumentTitle from '../utils/useDocumentTitle';

const Prices: FC = () => {
    const [services, setServices] = useState<Array<object>>([]);
    const location = useLocation();

    useDocumentTitle('Стоимость услуг | Искусство бокса', true);

    const columns = useMemo(
        () => [
            {
                Header: 'Услуга',
                accessor: 'service.name'
            },
            {
                Header: 'Стоимость',
                accessor: 'service.price'
            },
            {
                Header: 'Скидка',
                accessor: 'service.discount'
            },
            {
                Header: 'Цена за ед.',
                accessor: 'service.unitPrice'
            },
            {
                Header: 'Срок годности',
                accessor: 'service.expire'
            },
        ],
        []
    );


    useEffect(() => {
        let mounted = true;
        if (mounted) {
            const data = servicesJSON as Array<object>;
            setServices(data);
        }
        return () => {
            mounted = false;
        };
    }, []);


    return (
        <>
            <Breadcrumbs
                location={location}
                title='Стоимость услуг'
            />
            <h1>Стоимость услуг</h1>
            <Table columns={columns} data={services}/>
        </>
    );
};

export default Prices;
