import React, {FC, useRef} from 'react';
import CallbackForm, {CallbackFormProps, CallbackFormType} from './CallbackForm';
import {useAppDispatch} from '../redux/store';
import Modal from '../styles/components/Modal';
import {toggleModalQuestions, toggleModalRecord} from '../redux/slices/uiSlice';
import {ActionCreatorWithoutPayload} from '@reduxjs/toolkit';

const ModalCallbackForm: FC<CallbackFormProps> = ({type}) => {
    const dispatch = useAppDispatch()
    const bgRef = useRef(null);

    let toggle: ActionCreatorWithoutPayload = toggleModalQuestions
    switch (type) {
        case CallbackFormType.QUESTIONS:
            toggle = toggleModalQuestions
            break
        case CallbackFormType.RECORD:
            toggle = toggleModalRecord
            break
    }

    return (
        <Modal.Background
            onClick={(e) => {
                if (e.target === bgRef.current) dispatch(toggle())
            }}
            ref={bgRef}
        >
            <Modal.Wrapper>
                <CallbackForm type={type} close={toggle}/>
            </Modal.Wrapper>
        </Modal.Background>
    );
};

export default ModalCallbackForm;
