import styled from 'styled-components';
import Crumple from '../assets/main/crumple.jpg';

const Boxer = {
    Wrap: styled.div`
        color: white;
        margin-bottom: 6.25rem;
    `,
    Row: styled.div`
        display: flex;
        @media (max-width: 960px) {
            flex-direction: column;
            align-items: center;
        }
    `,
    Img: styled.div`
        padding-right: 0.625rem;
        padding-left: 0.625rem;
        
        background-image: url(${Crumple});
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        -webkit-transition: opacity .2s;
        transition: opacity .2s;
        width: 37.5%;
        margin-left: 8.3%;
        
        img {
            position: absolute;
            top: 5rem;
            left: 33%;
            min-width: 39.375rem;
            max-height: 22.3125rem;
            
            -webkit-animation: backInRight ease-out 2s;
            animation: backInRight ease-out 2s;
            
            @media (max-width: 960px) {
                min-width: 21.875rem;
                width: 66%;
                top: 0;
                bottom: 0;
                margin: auto 0;
            }
            
            @media (max-width: 640px) {
                min-width: 14.875rem;
                width: 90%;
                left: 20px;
            }    
        }
        
        @media (max-width: 960px) {
            height: 28.75rem;
            background-size: contain;
            margin-bottom: 1.25rem;
            width: 91.5%;
        }
        
        @media (max-width: 640px) {
            height: 20.625rem;
        }
    `,
    Text: styled.div`
        padding-right: 0.625rem;
        padding-left: 0.625rem;
        
        padding-top: 27.5rem;
        max-width: 21.875rem;
        font-size: 1.125rem;
        line-height: 1.55;
        -webkit-transition: opacity .2s;
        transition: opacity .2s;
        
        -webkit-animation: slideInUp ease-out 1s;
        animation: slideInUp ease-out 1s;
        
        @media (max-width: 960px) {
            padding-top: 0;
            max-width: unset;
            text-align: center;
        }
    `
}

export default Boxer