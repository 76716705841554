import styled from 'styled-components';

const StyledTable = {
    Wrapper: styled.table`
        margin-bottom: 1.5rem;
        border-collapse: collapse;
        width: 100%;
        border-radius: 0.25rem;
    `,
    Header: styled.thead`
      background-color: #f2f2f2;
      border: .0625rem solid #f2f2f2;
      text-align: left;
    `,
    HeaderRow: styled.th`
      font-weight: 700;
      padding: 0.5rem 0.625rem 0.625rem;
      vertical-align: top;
    `,
    Row: styled.tr`
      &:nth-child(even) {
        background-color: #f2f2f2;
        border-bottom: 0;
      }
    `,
    Cell: styled.td`
      vertical-align: top;
      padding: 0.5rem .625rem
    `,
    Body: styled.tbody`
      background: #fff;
      border: 0.0625rem solid #f2f2f2;
    `
}

export default StyledTable;
