import React, {FC} from 'react';

import Header from '../components/Header';
import Container from '../styles/components/Container';
import Button from '../styles/components/Button';

import Gloves from '../assets/main/gloves.png'
import Man from '../assets/main/man.jpg'
import girl from '../assets/main/girl-boxer.jpg'

import Studio from '../styles/MainStudio';
import Boxer from '../styles/MainBoxer';
import About from '../styles/MainAbout';
import CircleText from '../assets/main/CircleText';
import {Link} from 'react-router-dom';
import { MainWrapper, Note } from '../styles/Main';
import {useAppDispatch} from '../redux/store';
import {toggleModalRecord} from '../redux/slices/uiSlice';
import useDocumentTitle from '../utils/useDocumentTitle';

const Main: FC = () => {
    const dispatch = useAppDispatch()

    useDocumentTitle('Главная | Искусство бокса', true);

    return (
        <MainWrapper>
            <Header/>
            <Container>
                <Studio.Wrap>
                    <Studio.WordWrap>
                        <Studio.Word>СТУДИЯ</Studio.Word>
                    </Studio.WordWrap>
                    <Studio.Row>
                        <Studio.Text>
                            <Studio.Title>
                                <h1>Искусство бокса</h1>
                                <Button
                                    onClick={() => dispatch(toggleModalRecord())}
                                    transparent
                                    padding={'1.5rem 2.3125rem'}
                                >
                                    Запись на стартовую тренировку
                                </Button>
                            </Studio.Title>
                        </Studio.Text>
                        <Studio.Gloves>
                                <img src={Gloves} alt='Gloves'/>
                        </Studio.Gloves>
                    </Studio.Row>
                </Studio.Wrap>
                <Boxer.Wrap>
                    <Boxer.Row>
                        <Boxer.Img>
                            <img src={Man} alt='Man'/>
                        </Boxer.Img>
                        <Boxer.Text>
                            <h3>Одни из самых популярных спортивных направлений в России</h3>
                        </Boxer.Text>
                    </Boxer.Row>
                </Boxer.Wrap>
                <About.Wrap>
                    <About.Row>
                        <About.Project>
                            <About.Desc>
                                <Link className={'h1'} to={'/about'}>
                                    Наш проект и глобальная цель
                                </Link>
                                <Link className={'h3'} to={'/auction'}>Промо-акции</Link>
                            </About.Desc>
                            <About.Animated>
                                <CircleText/>
                            </About.Animated>
                        </About.Project>
                        <About.Benefits>
                            <About.BenefitsWrap>
                                <img src={girl} alt='girl'/>
                                <About.BenefitsList>
                                    <About.BenefitsTitle className={'h1'}>
                                        Студия «Искусство Бокса»
                                    </About.BenefitsTitle>
                                    <About.BenefitsText>
                                        <span>Наши</span> преимущества
                                    </About.BenefitsText>
                                    <p>
                                        - Индивидуальный подход<br/>Учитываются индивидуальные особенности каждого
                                        клиента
                                    </p>
                                    <p>
                                        - Желаемый результат за считанные занятия
                                    </p>
                                    <p>
                                        - Профессиональные тренера<br/>Мастера спорта, участники и победители
                                        международных турниров
                                    </p>
                                    <p>
                                        - Оборудование лучших мировых брендов<br/>Cleto Reyes, PRO Boxing, Everlast и
                                        пр.
                                    </p>
                                    <p>
                                        - Удобное время<br/>Вы занимаетесь в удобное Вам время. Никаких переполненных
                                        залов или очередей на
                                        тренажеры, все индивидуально
                                    </p>
                                </About.BenefitsList>
                            </About.BenefitsWrap>
                        </About.Benefits>
                    </About.Row>
                </About.Wrap>
                <Note>
                    <Button
                        onClick={() => dispatch(toggleModalRecord())}
                        transparent
                        padding={'1.5rem 2.3125rem'}
                    >
                        Запись на стартовую тренировку
                    </Button>
                </Note>
            </Container>
        </MainWrapper>
    );
};

export default Main;
